$(function(){

  //何度も .nav を探しに行かなくて済むように定数に入れておく
  //この後は $nav が $(".nav") を示す
  const $nav = $(".nav");
  // ハンバーガーボタンをクリックしたら
  $(".page-header__menu-button").click(function(){
    // --is-open class が付いていた場合
    if($(this).hasClass("page-header__menu-button--is-open")){
        // when()内の処理が終わってからdone()内の処理を実行。()内は行末;を書かない
        $.when(
            // メニューが開いている時
            $nav.slideUp(250)
        ).done(function() {
            // display:none; 指定を削除
            $nav.css("display", "")
        });
        $(this).removeClass("page-header__menu-button--is-open");
    } else {
        // メニューを開く
        $nav.slideDown(250);
        // classを追加
        $(this).addClass("page-header__menu-button--is-open");
    }
  })
})
